@layer utilities
	.container, .container-fluid
		width: 100%
		padding-right: 16px
		padding-left: 16px
		margin-right: auto
		margin-left: auto
		@screen xl
			padding-right: rr(16px)
			padding-left: rr(16px)
	.container
		@media (min-width: 768px)
			max-width: 740px !important
		@media (min-width: 960px)
			max-width: 900px !important
		@media (min-width: 1024px)
			max-width: rr(990px) !important
		@media (min-width: 1280px)
			max-width: 90vw !important
		@media (min-width: 1440px)
			max-width: rr(1536px) !important
