.home-2
	.home-2-item
		&::before
			content: ''
			@apply bg-black opacity-40 inset-0 absolute z-1 c-transall
	.innert-content
		@apply z-2 relative text-white
	@screen xl
		.home-2-item
			height: rr(760px)
			@apply overflow-hidden
			background-image: none !important
			&::before
				@apply opacity-0
			&+.home-2-item
				&::after
					content: ''
					width: 1px
					background-color: rgba(255, 255, 255, 0.40)
					@apply absolute left-0 bottom-0 h-full z-2
			.innert-content
				transform: translateY(calc( 100% - rr(132px) ))
				@apply absolute z-2 left-0 bottom-0 p-10 c-transall
				&::after
					content: ''
					background: linear-gradient(225deg, #67C9E2 0%, #70D6A3 100%)
					@apply w-full absolute bottom-0 left-0 h-1 bg-linear-2 c-transall opacity-0
			&:hover
				&::before
					@apply opacity-40
				.innert-content
					transform: translateY(0)
				.desc, .button-wrapper, .innert-content::after
					@apply opacity-100
			.desc
				@apply opacity-0 c-transall
			.button-wrapper
				@apply opacity-0 c-transall
	@media(max-width: 1279.98px)
		.home-2-item
			@apply px-5 py-8
		.home-2-content
			background-image: none !important
