.swiper-primary
	@apply relative
	@media(max-width: 1279.98px)
		@apply pb-5

.wrap-button-slide
	display: flex
	align-items: center
	.btn-prev, .btn-next
		@apply cursor-pointer c-transall
		&:before
			@apply font-awesome text-xl font-thin c-transall
	.btn-prev
		&:before
			content: '\f053'
	.btn-next
		&:before
			content: '\f054'
	.swiper-button-disabled
		@apply opacity-60
	&.slide-primary
		.btn-prev, .btn-next
			background: rgba(0, 0, 0, 0.10)
			@apply w-10 h-10 rounded-full flex-center
			@apply absolute top-1/2 -translate-y-1/2 z-10
			&::before
				@apply text-white
			&:hover
				&::before
					@apply text-white
				@apply bg-primary-600
		.btn-prev
			@apply -left-20
		.btn-next
			@apply -right-20
		@screen 2xl
			.btn-prev
				@apply -left-23
			.btn-next
				@apply -right-23
		@media(max-width: 1279.98px)
			@apply hidden
	&.slide-secondary
		.btn-prev, .btn-next
			width: rr(50px)
			height: rr(50px)
			@apply flex-center bg-black/50 rounded-1
			@apply absolute top-1/2 -translate-y-1/2 z-10
			&::before
				@apply text-white z-2 text-32
		.btn-prev
			@apply left-0
			@screen lg
				left: rr(176px)
		.btn-next
			@apply right-0
			@screen lg
				right: rr(176px)
		@media(max-width: 1279.98px)
			@apply hidden
.wrap-navigation-slide
	&.progressbar-primary
		@apply block
		.swiper-pagination
			top: unset
			@apply bottom-0
		@screen xl
			@apply hidden
	&.progressbar-secondary
		.swiper-pagination
			top: unset
			@apply bottom-2.5 text-left left-1/2 -translate-x-1/2
			@media (min-width: 768px)
				max-width: 708px
			@media (min-width: 960px)
				max-width: 868px
			@media (min-width: 1024px)
				max-width: rr(958px)
				bottom: rr(120px)
			@media (min-width: 1280px)
				max-width: 95vw
			@media (min-width: 1440px)
				max-width: rr(1312px)
		.swiper-pagination-bullet
			border-radius: rr(100px)
			@apply bg-white opacity-50 c-transall w-5 h-1
			&.swiper-pagination-bullet-active
				@apply opacity-100 w-10

.swiper-button-lock
	@apply hidden #{!important}
