:root
	--header-height: 80px

header
	height: var(--header-height)
	@apply flex items-center bg-white fixed top-0 left-0 w-full z-1000 shadow-dropshadow-light

	.header-wrapper
		@apply flex items-center justify-between

	.header-logo
		a
			@apply block w-full
			img
				@apply object-contain w-full
		@screen xl
			flex: 0 0 calc(128/1504*100%)
			max-width: calc(128/1504*100%)
			width: 100%
		@media(max-width: 1279.98px)
			@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
			width: rr(118px)
			height: rr(50px)
	.header-right
		@apply flex items-center

	.header-menu
		&>ul
			gap: rr(82px)
			@apply flex
			&>li
				&.active, &:hover
					&>a, &>.title>a
						@apply text-primary-500
						&::after
							@apply w-full opacity-100 visible
				&>a, &>.title>a
					padding: rr(26px) 0
					@apply whitespace-nowrap relative inline-block
					&::after
						content: ''
						height: rr(2px)
						@apply w-0 opacity-0 invisible transition-all absolute bottom-0 left-0 bg-linear-2
				&>ul
					width: rr(345px)
					@apply absolute top-full left-0 bg-white py-1 px-6 opacity-0 invisible transition-all
					&>li
						&.active, &:hover
							&>a, &>.title>a
								@apply text-primary-500
						&+li
							@apply border-t border-neutral-100
						&>a
							@apply inline-flex py-5
		ul
			li
				@apply relative
				&[class*='has-children']
					&:hover
						&>ul
							@apply opacity-100 visible
				a
					@apply body-18 transition-all
	.language-wrap
		margin-left: rr(70px)
		&:hover
			.drop-down
				@apply opacity-100 visible
		.current-language
			@apply flex gap-2 items-center
		.drop-down
			@apply absolute top-full left-0 p-2 bg-white opacity-0 invisible transition-all

	.header-search
		.searchbox
			@apply relative
			input
				height: rr(44px)
				@apply bg-neutral-50 pl-5 pr-15
				&:placeholder-shown, &::placeholder
					@apply text-neutral-950 body-18
			button
				@apply absolute top-1/2 -translate-y-1/2 right-5 flex-center
				em
					@apply text-[#818285] text-2xl
	.header-search-mobile
		em
			@apply text-[#818285] text-2xl

	.header-hambuger
		@apply flex-shrink-0 basis-[calc(36/1920*100rem)] max-w-[calc(36/1920*100rem)] w-full flex flex-col gap-1 mt-auto mb-auto xl:hidden
		&.active
			span
				&:first-child
					transform: translateY(7px) rotate(45deg)
				&:nth-child(2)
					transform: scaleX(0)
				&:last-child
					transform: translateY(-7px) rotate(-45deg)
		span
			height: rr(5px)
			@apply bg-primary-500 pointer-events-none block w-full transition-all duration-300 rounded-3xl

	@media(max-width: 1279.98px)
		.header-menu, .language-wrap, .header-search
			@apply hidden

.header-menu-mobile
	top: var(--header-height)
	&.active
		@apply translate-x-0
	@apply fixed left-0 h-screen w-full xl:hidden bg-white
	@apply transition-all duration-500 -translate-x-full z-100
	@screen md
		width: rr(430px)
	.language-wrap
		ul
			@apply flex gap-3 py-4 px-5 border-t border-neutral-200
	.header-menu
		&>ul
			&>li
				@apply px-5 border-t border-neutral-200
				&.active
					&>a, &>.title>a
						@apply text-primary-500
				&>a, &>.title>a
					@apply inline-flex py-4 sub-header-20 font-bold
				&>.title
					@apply flex justify-between items-center
				&.show-child
					.icon
						@apply -rotate-180
				.icon
					@apply flex-center w-8 h-8 transition-all
					&::after
						content: '\f078'
						@apply font-awesome font-normal text-2xl
				&>ul
					@apply pb-5 hidden
					&>li
						&+li
							@apply mt-2