.home-1
	@apply overflow-hidden
	&::before
		content: ''
		background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)
		height: rr(446px)
		@apply w-full absolute top-0 left-0
	@media(max-width: 1279.98px)
		background-image: none !important
		&::before
			content: none
	.home-1-item
		outline: 2px solid rgba(255, 255, 255, 0.70)
		&::before
			content: ''
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.85) 100%)
			height: rr(58px)
			@apply w-full absolute bottom-0 left-0 z-1
