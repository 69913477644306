form.invalid
	.wpcf7-response-output
		@apply text-red-600 border-red-600 bg-white #{!important}
.wpcf7-response-output
	@apply text-green-400 border-green-400 bg-white border py-1 px-4 text-center mt-2 mb-0 ml-0 mr-0 text-sm #{!important}
.form-group
	@apply relative
	.wpcf7-not-valid-tip
		@apply absolute top-1/2 -translate-y-1/2 right-3 text-xs text-red-600
.frm-submit
	@apply relative
	.wpcf7-spinner
		@apply absolute left-full top-1/2 -translate-y-1/2 ml-2
