.h-full-module
	[class*="Module"]
		height: 100%
.notfound .notfound-404 h1
	-webkit-background-clip: text
	color: transparent
	background-image: linear-gradient(to right, #ed1b24, #bc0049) !important
	display: inline-block
.notfound a
	background: linear-gradient(90deg,#ed1b24,#bc0049) !important
.mobile-only
	@apply block lg:hidden
.desktop-only
	@apply hidden lg:block
