.popup-product
	.column-left
		.wrap-button-slide
			@apply absolute bottom-0 right-0
		.btn-prev, .btn-next
			height: rr(68px)
			width: rr(50px)
			@apply static translate-y-0 bg-transparent rounded-none
			&::before
				@apply text-primary-500 font-normal
			&:hover
				@apply bg-transparent
				&::before
					@apply text-primary-500
	.column-left, .column-right
		flex: 0 0 100%
		max-width: 100%
		@apply w-full
		table
			tr
				&+tr
					@apply border-t border-neutral-200
				td
					@apply py-3
					&:first-child
						width: rr(167px)
						@apply font-bold
	@screen xl
		.wrapper
			height: rr(524px)
			@apply overflow-y-auto
		.column-left
			flex: 0 0 calc(808/1334*100%)
			max-width: calc(808/1334*100%)
		.column-right
			flex: 0 0 calc(493/1334*100%)
			max-width: calc(493/1334*100%)

	@media(max-width: 1279.98px)
		.column-right
			@apply p-5