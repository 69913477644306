@charset "UTF-8";
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
}

/* Font Arial*/
@font-face {
  font-family: "Arial";
  src: url("../fonts/ArialMT.woff2") format("woff2"), url("../fonts/ArialMT.woff") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arial";
  src: url("../fonts/Arial-BoldMT.woff2") format("woff2"), url("../fonts/Arial-BoldMT.woff") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
form.invalid .wpcf7-response-output {
  @apply text-red-600 border-red-600 bg-white !important;
}

.wpcf7-response-output {
  @apply text-green-400 border-green-400 bg-white border py-1 px-4 text-center mt-2 mb-0 ml-0 mr-0 text-sm !important;
}

.form-group {
  @apply relative;
}
.form-group .wpcf7-not-valid-tip {
  @apply absolute top-1/2 -translate-y-1/2 right-3 text-xs text-red-600;
}

.frm-submit {
  @apply relative;
}
.frm-submit .wpcf7-spinner {
  @apply absolute left-full top-1/2 -translate-y-1/2 ml-2;
}

.edit-link i:before {
  content: "✏️";
}

@media (max-width: 575.89px) {
  .fancybox__content > .carousel__button.is-close {
    right: 0 !important;
    top: -34px !important;
  }
}

.swiper-primary {
  @apply relative;
}
@media (max-width: 1279.98px) {
  .swiper-primary {
    @apply pb-5;
  }
}

.wrap-button-slide {
  display: flex;
  align-items: center;
}
.wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
  @apply cursor-pointer c-transall;
}
.wrap-button-slide .btn-prev:before, .wrap-button-slide .btn-next:before {
  @apply font-awesome text-xl font-thin c-transall;
}
.wrap-button-slide .btn-prev:before {
  content: "\f053";
}
.wrap-button-slide .btn-next:before {
  content: "\f054";
}
.wrap-button-slide .swiper-button-disabled {
  @apply opacity-60;
}
.wrap-button-slide.slide-primary .btn-prev, .wrap-button-slide.slide-primary .btn-next {
  background: rgba(0, 0, 0, 0.1);
  @apply w-10 h-10 rounded-full flex-center;
  @apply absolute top-1/2 -translate-y-1/2 z-10;
}
.wrap-button-slide.slide-primary .btn-prev::before, .wrap-button-slide.slide-primary .btn-next::before {
  @apply text-white;
}
.wrap-button-slide.slide-primary .btn-prev:hover, .wrap-button-slide.slide-primary .btn-next:hover {
  @apply bg-primary-600;
}
.wrap-button-slide.slide-primary .btn-prev:hover::before, .wrap-button-slide.slide-primary .btn-next:hover::before {
  @apply text-white;
}
.wrap-button-slide.slide-primary .btn-prev {
  @apply -left-20;
}
.wrap-button-slide.slide-primary .btn-next {
  @apply -right-20;
}
@screen 2xl {
  .wrap-button-slide.slide-primary .btn-prev {
    @apply -left-23;
  }
  .wrap-button-slide.slide-primary .btn-next {
    @apply -right-23;
  }
}
@media (max-width: 1279.98px) {
  .wrap-button-slide.slide-primary {
    @apply hidden;
  }
}
.wrap-button-slide.slide-secondary .btn-prev, .wrap-button-slide.slide-secondary .btn-next {
  width: 2.6041666667rem;
  height: 2.6041666667rem;
  @apply flex-center bg-black/50 rounded-1;
  @apply absolute top-1/2 -translate-y-1/2 z-10;
}
.wrap-button-slide.slide-secondary .btn-prev::before, .wrap-button-slide.slide-secondary .btn-next::before {
  @apply text-white z-2 text-32;
}
.wrap-button-slide.slide-secondary .btn-prev {
  @apply left-0;
}
@screen lg {
  .wrap-button-slide.slide-secondary .btn-prev {
    left: 9.1666666667rem;
  }
}
.wrap-button-slide.slide-secondary .btn-next {
  @apply right-0;
}
@screen lg {
  .wrap-button-slide.slide-secondary .btn-next {
    right: 9.1666666667rem;
  }
}
@media (max-width: 1279.98px) {
  .wrap-button-slide.slide-secondary {
    @apply hidden;
  }
}

.wrap-navigation-slide.progressbar-primary {
  @apply block;
}
.wrap-navigation-slide.progressbar-primary .swiper-pagination {
  top: unset;
  @apply bottom-0;
}
@screen xl {
  .wrap-navigation-slide.progressbar-primary {
    @apply hidden;
  }
}
.wrap-navigation-slide.progressbar-secondary .swiper-pagination {
  top: unset;
  @apply bottom-2.5 text-left left-1/2 -translate-x-1/2;
}
@media (min-width: 768px) {
  .wrap-navigation-slide.progressbar-secondary .swiper-pagination {
    max-width: 708px;
  }
}
@media (min-width: 960px) {
  .wrap-navigation-slide.progressbar-secondary .swiper-pagination {
    max-width: 868px;
  }
}
@media (min-width: 1024px) {
  .wrap-navigation-slide.progressbar-secondary .swiper-pagination {
    max-width: 49.8958333333rem;
    bottom: 6.25rem;
  }
}
@media (min-width: 1280px) {
  .wrap-navigation-slide.progressbar-secondary .swiper-pagination {
    max-width: 95vw;
  }
}
@media (min-width: 1440px) {
  .wrap-navigation-slide.progressbar-secondary .swiper-pagination {
    max-width: 68.3333333333rem;
  }
}
.wrap-navigation-slide.progressbar-secondary .swiper-pagination-bullet {
  border-radius: 5.2083333333rem;
  @apply bg-white opacity-50 c-transall w-5 h-1;
}
.wrap-navigation-slide.progressbar-secondary .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply opacity-100 w-10;
}

.swiper-button-lock {
  @apply hidden !important;
}

.zoom-img {
  overflow: hidden;
}
.zoom-img:hover img {
  transform: scale(1.05) translateZ(0);
}
.zoom-img img {
  transition: 0.5s all ease-in-out !important;
}

.ajaxResponse > div {
  animation: 0.5s 0.25s fade-in-up ease-in-out both;
}

.ring-food {
  animation: 1.5s ring-food infinite ease-in-out alternate;
}

.ring-chili {
  animation: 1.5s ring-chili infinite ease-in-out alternate;
}

.ring-food-footer {
  animation: 1.5s ring-food-footer infinite ease-in-out alternate;
}

@keyframes ring-chili {
  0% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
@keyframes ring-food-footer {
  0% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes ring-food {
  0% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(330deg);
  }
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@layer base {
  html, body {
    font-size: 15px;
    scroll-behavior: smooth;
  }
  @screen md {
    html, body {
      font-size: 15px;
    }
  }
  @screen xl {
    html, body {
      font-size: 1vw;
    }
  }
  .table-responsive {
    @apply overflow-auto;
  }
  @media (max-width: 767.98px) {
    .table-responsive table {
      width: 900px !important;
    }
  }
  .img-full img {
    @apply w-full h-full object-cover;
  }
  .img-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .img-ratio img, .img-ratio iframe {
    @apply absolute top-0 left-0 w-full h-full object-cover transition-all duration-300;
  }
  .img-ratio-center {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    @apply flex-center;
  }
  .img-ratio-center img, .img-ratio-center iframe {
    @apply absolute top-1/2 left-1/2 w-auto h-auto object-contain transition-all duration-300 max-w-full max-h-full -translate-x-1/2 -translate-y-1/2;
  }
  .div-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .div-ratio > div {
    @apply absolute top-0 left-0 w-full h-full transition-all duration-300;
  }
  body {
    @apply text-neutral-950 body-16 font-normal font-primary;
  }
  body::-webkit-scrollbar {
    width: 0px;
  }
  .row {
    @apply flex flex-wrap -mx-[calc(16/1920*100rem)];
  }
  .col {
    @apply px-[calc(16/1920*100rem)] w-full;
  }
  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
  h4 {
    @apply text-lg font-bold;
  }
  img {
    @apply inline max-w-full;
  }
  video, iframe {
    @apply max-w-full;
  }
  a {
    @apply transition-all ease-linear duration-200;
  }
  p {
    @apply empty:hidden;
  }
  input {
    @apply outline-offset-0 shadow-none ring-0 !important;
  }
  .section-40 {
    @apply lg:py-10 py-6;
  }
  .margin-p p + p {
    @apply mt-5;
  }
  .overflow-scroll-1::-webkit-scrollbar {
    @apply w-[calc(6/1920*100rem)];
  }
  .overflow-scroll-1::-webkit-scrollbar-track {
    background-color: rgba(229, 229, 229, 0.2);
  }
  .overflow-scroll-1::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }
  .overlay-black::before {
    content: "";
    @apply absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 pointer-events-none z-10 whitespace-nowrap;
  }
  main {
    padding-top: var(--header-height);
  }
  .homepage main {
    @apply pt-0;
  }
  @media (max-width: 767.98px) {
    .homepage main {
      padding-top: var(--header-height);
    }
  }
}
@layer utilities {
  .container, .container-fluid {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
  }
  @screen xl {
    .container, .container-fluid {
      padding-right: 0.8333333333rem;
      padding-left: 0.8333333333rem;
    }
  }
  @media (min-width: 768px) {
    .container {
      max-width: 740px !important;
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 900px !important;
    }
  }
  @media (min-width: 1024px) {
    .container {
      max-width: 51.5625rem !important;
    }
  }
  @media (min-width: 1280px) {
    .container {
      max-width: 90vw !important;
    }
  }
  @media (min-width: 1440px) {
    .container {
      max-width: 80rem !important;
    }
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.loginpage .panel-body .flex.justify-between.mt-3 {
  display: none;
}
.loginpage header {
  display: none;
}
.loginpage footer {
  display: none;
}
.loginpage main {
  background-color: #F0F0F1;
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
  padding-top: 0 !important;
  padding: 0 15px;
}
.loginpage .header-tools-mobile {
  display: none !important;
}
.loginpage .login-box {
  max-width: 690px;
  margin: 0 auto;
  width: 100%;
}
.loginpage .login-box .login-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex-direction: column;
  font-size: 0;
  margin-bottom: 30px;
}
.loginpage .login-box .login-logo::before {
  content: "";
  display: inline-block;
  background-image: url("/Data/Sites/1/skins/default/img/logo-canh-cam.png");
  width: 267px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: 267px auto;
  margin-bottom: 15px;
}
.loginpage .login-box .loginstandard {
  background-color: #fff;
  box-shadow: 0, 4px, 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 0;
  padding: 40px 60px 30px;
  font-weight: 400;
  overflow: hidden;
  font-family: Roboto, sans-serif !important;
}
@media (max-width: 767.98px) {
  .loginpage .login-box .loginstandard {
    padding: 15px 20px;
  }
}
.loginpage .login-box .loginstandard .card-body .row.items-center {
  @apply flex-wrap mx-0;
}
.loginpage .login-box .loginstandard .card-body .row.items-center .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.loginpage .login-box .loginstandard .card-body .login-box-msg {
  display: none;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group {
  position: relative;
  margin-bottom: 1.25rem;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group .input-group-append {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #d1d1d1;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input {
  background: #f5f5f5;
  border-radius: 25px;
  height: 50px;
  border: 0;
  outline: none;
  box-shadow: 0 0 0 1px #f5f5f5;
  transition: 0.3s all ease-in-out;
  padding: 0 35px;
  font-size: 14px;
  width: 100%;
  border: 1px solid transparent;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input:hover, .loginpage .login-box .loginstandard .card-body .panel-body .input-group input:focus {
  border: 1px solid #f73936;
}
.loginpage .login-box .loginstandard .card-body .panel-body .icheck-primary {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  margin-bottom: 20px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .btn-primary {
  background-color: #f73936;
  border-radius: 25px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  width: 100%;
  font-size: 20px;
  border: 0;
  outline: none;
  transition: 0.3s all ease-in-out;
}
.loginpage .login-box .loginstandard .card-body .panel-body .flex.justify-between.mt-3 {
  margin-top: 30px;
  font-size: 14px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .cart-login {
  display: none;
}
.loginpage .login-box .loginstandard .card-body span.alert {
  color: #f73936;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

span.fa-exclamation-triangle {
  @apply text-red-500 absolute top-1/2 right-3 -translate-y-1/2 text-xs md:text-sm font-primary font-normal;
}
span.fa-exclamation-triangle::before {
  display: none;
}

.btn.btn-default.frm-btn-reset {
  @apply hidden;
}

.wrap-form-submit {
  @apply flex items-center flex-wrap justify-between gap-5 md:gap-4;
}
.wrap-form-submit .frm-captcha, .wrap-form-submit .frm-btnwrap {
  opacity: 1;
}
.wrap-form-submit .frm-captcha {
  @apply flex flex-row-reverse items-center flex-1;
}
.wrap-form-submit .frm-captcha .RadCaptcha {
  width: fit-content !important;
  @apply relative;
}
.wrap-form-submit .frm-captcha .RadCaptcha > span {
  @apply absolute top-full left-0 text-red-500;
}
.wrap-form-submit .frm-captcha .RadCaptcha > div > div {
  @apply flex items-center;
}
.wrap-form-submit .frm-captcha .RadCaptcha img {
  width: 8.28125rem !important;
  height: 2.6041666667rem !important;
  @apply border border-[#EEEEEE] !important;
}
.wrap-form-submit .frm-captcha .RadCaptcha a.rcRefreshImage {
  @apply text-0 before:content-[""] before:font-awesome before:text-2xl before:mx-3 before:inline-block w-full h-full text-neutral-black;
  @apply flex-center !important;
}
.wrap-form-submit .frm-captcha .frm-captcha-input {
  @apply flex-1;
}
.wrap-form-submit .frm-captcha .frm-captcha-input label {
  @apply hidden;
}
@media (max-width: 767.98px) {
  .wrap-form-submit .frm-btnwrap {
    @apply flex-[0_0_100%];
  }
}
.wrap-form-submit .frm-btnwrap label {
  @apply hidden;
}

.RadUpload {
  width: auto !important;
}

.search-page {
  padding: 40px 0;
}
.search-page h1 {
  letter-spacing: -2px;
  margin-bottom: 20px;
  font-size: 45px;
  line-height: 55px;
  font-weight: 500;
  color: #333333;
}
@media (max-width: 1024px) {
  .search-page h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.search-page h1.center {
  text-align: center;
}
.search-page .btn-reindex {
  display: none;
}
.search-page .searchcontrols .form-group {
  position: static;
}
.search-page .searchcontrols .form-inline {
  position: relative;
}
.search-page .searchcontrols .form-inline:before {
  content: "\f002";
  @apply absolute h-12.5 flex-center px-4 font-awesome text-red-500 top-0 right-0;
}
.search-page .searchcontrols .frm-btn {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}
.search-page input[type=text] {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 54px;
  border: 1px solid #dfe1e5;
  background-color: #fff;
  color: #333;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 22px;
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  @apply h-12.5;
}
.search-page input[type=submit] {
  @apply absolute top-0 right-0 h-full flex-center px-4 h-12.5 text-0;
}
.search-page .text-danger {
  margin-top: 20px;
  font-size: 15px;
  color: #333;
  font-style: italic;
  font-weight: 600;
}
.search-page .searchresultsummary {
  margin-bottom: 20px;
}
.search-page .searchresults {
  margin-top: 30px;
}
.search-page .searchresults .modulepager:first-child {
  display: none;
}
.search-page .searchresultlist {
  margin-bottom: 20px;
}
.search-page .searchresult {
  margin-bottom: 30px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  padding: 16px;
  border-radius: 8px;
}
.search-page .searchresult:last-child {
  margin-bottom: 0;
}
.search-page .searchresult h3 {
  font-size: 18px;
  line-height: 1.33;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult h3 a {
  color: #1A0DAB;
  text-decoration: none;
  font-weight: 500;
}
.search-page .searchresult h3 a:hover {
  text-decoration: underline;
}
.search-page .searchresult .searchresultdesc {
  color: #545454;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult .searchterm {
  color: #6a6a6a;
  font-weight: bold;
  font-style: normal;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
}
.search-page .searchresult hr {
  display: none !important;
}

.h-full-module [class*=Module] {
  height: 100%;
}

.notfound .notfound-404 h1 {
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #ed1b24, #bc0049) !important;
  display: inline-block;
}

.notfound a {
  background: linear-gradient(90deg, #ed1b24, #bc0049) !important;
}

.mobile-only {
  @apply block lg:hidden;
}

.desktop-only {
  @apply hidden lg:block;
}

.header-search-form {
  visibility: hidden;
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 backdrop-blur-md z-[1000] flex justify-center items-center transition-all duration-300 opacity-0  pointer-events-none;
}
.header-search-form .close {
  @apply right-0;
}
.header-search-form.active {
  @apply opacity-100 visible pointer-events-auto;
}
.header-search-form .wrap-form-search-product {
  max-width: 50rem;
  margin: 0 auto;
  @apply w-full;
}
.header-search-form .wrap-form-search-product .productsearchbox {
  @apply w-full flex relative flex-wrap;
}
.header-search-form .wrap-form-search-product .productsearchbox [class*=Module] {
  width: 100% !important;
}
.header-search-form .wrap-form-search-product .productsearchbox .searchbox {
  @apply w-full flex;
}
.header-search-form .wrap-form-search-product .productsearchbox input {
  @apply flex-1 h-[calc(50/1920*100rem)] bg-white text-base px-6 outline-0 border-0 !important;
}
.header-search-form .wrap-form-search-product .productsearchbox button {
  @apply w-9 flex items-center justify-center bg-white text-[0px] outline-0 border-0 border-l;
}
.header-search-form .wrap-form-search-product .productsearchbox button::before {
  content: "\f002";
  font-family: "Font Awesome 6 Pro";
  color: #222222;
  @apply text-base;
}

.btn {
  @apply inline-flex items-center cursor-pointer c-transall;
  @apply px-6 text-white;
}
.btn.btn-primary {
  height: 2.7083333333rem;
  background: linear-gradient(225deg, #67C9E2 0%, #70D6A3 100%);
  @apply border border-primary-300 rounded-1 bg-linear-2;
}
.btn.btn-primary span {
  @apply body-18;
}
.btn.btn-primary:hover {
  background: #007DD3;
  @apply border-primary-500;
}

.pagination {
  @apply flex-center gap-5 mt-5 xl:mt-8;
}
.pagination li.active a, .pagination li.active span {
  @apply bg-primary-600 text-white;
}
.pagination a, .pagination span {
  @apply bg-neutral-50 rounded-full text-neutral-400 sub-header-20;
  @apply inline-flex justify-center items-center w-12 h-12;
  @apply c-transall;
}
.pagination a:hover, .pagination span:hover {
  @apply bg-primary-600 text-white;
}
.pagination a.FirstPage, .pagination a.BackPage, .pagination a.NextPage, .pagination a.LastPage, .pagination span.FirstPage, .pagination span.BackPage, .pagination span.NextPage, .pagination span.LastPage {
  @apply hidden;
}

.fancybox__content {
  @apply p-0;
}
.fancybox__content .is-close {
  @apply top-1 right-1 xl:top-7 xl:right-7 text-primary-500 rounded-none w-11 h-11 !important;
}

.popup-model {
  max-width: 73.6458333333rem;
  @apply w-full;
}

.section-large {
  @apply py-10;
}
@screen md {
  .section-large {
    @apply py-25;
  }
}

.section-medium {
  @apply py-10;
}
@screen md {
  .section-medium {
    @apply py-20;
  }
}

.section-small {
  @apply py-7.5;
}
@screen md {
  .section-small {
    @apply py-15;
  }
}

.global-title-primary {
  @apply header-40 font-bold text-primary-500;
}

.global-title-secondary {
  @apply section-header-32 text-primary-500 font-bold;
}

.global-title-thircondary {
  @apply sub-header-20 font-bold text-primary-500;
}

.global-tab ul {
  @apply flex gap-5 md:gap-8 overflow-x-auto;
}
.global-tab ul li.active a {
  @apply text-primary-500;
}
.global-tab ul li.active a::after {
  @apply w-full bg-primary-500;
}
.global-tab ul li a {
  @apply whitespace-nowrap relative text-neutral-700 font-bold c-transall;
}
.global-tab ul li a::after {
  content: "";
  height: 0.0520833333rem;
  @apply c-transall w-full bottom-0 left-0 bg-transparent absolute;
}
@screen xl {
  .global-tab ul li:hover a {
    @apply text-primary-500;
  }
  .global-tab ul li:hover a::after {
    @apply w-full bg-primary-500;
  }
  .global-tab ul li a::after {
    @apply w-0;
  }
}

.social-fixed li:nth-child(1) a {
  @apply bg-primary-500 h-[calc(48/1920*100rem)];
}
.social-fixed a {
  @apply flex-center flex-col w-[calc(72/1920*100rem)] text-white rounded-[calc(8/1920*100rem)] py-2 bg-white;
}
.social-fixed a em {
  @apply text-2xl;
}
.social-fixed a span {
  @apply text-[calc(12/1920*100rem)] leading-1.5 mt-1;
}

.social-fixed-wrapper .ActionMenuBody {
  left: unset !important;
  @apply right-0;
}

.global-breadcrumb {
  @apply bg-white border-b border-neutral-200;
}
.global-breadcrumb ol {
  @apply flex items-center py-3 overflow-x-auto text-neutral-500;
}
.global-breadcrumb li:not(:first-child)::before {
  content: "\f105";
  @apply font-awesome mx-2 font-thin text-base;
}
.global-breadcrumb li a {
  @apply body-14 whitespace-nowrap;
}

footer .footer-infos {
  @apply mt-6;
}
footer .footer-infos h4 {
  @apply sub-header-20 font-bold;
}
footer .footer-infos p {
  @apply mt-2;
}
footer .footer-infos p a {
  @apply border-b;
}
footer .footer-infos p a em {
  @apply mr-2;
}
footer .footer-contact {
  @apply mt-6;
}
footer .footer-contact ul li + li {
  @apply mt-1;
}
footer .footer-nav ul li + li {
  @apply mt-4;
}
footer .footer-nav ul li a {
  @apply sub-header-20 font-bold;
}
@screen xl {
  footer .footer-nav {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    @apply pl-10;
  }
}
footer .footer-social ul {
  @apply flex items-center gap-2;
}
footer .footer-social ul li a {
  @apply flex-center w-12 h-12 rounded-full bg-white;
}
footer .footer-social ul li a em {
  @apply text-primary-600 text-2xl;
}

:root {
  --header-height: 80px;
}

header {
  height: var(--header-height);
  @apply flex items-center bg-white fixed top-0 left-0 w-full z-1000 shadow-dropshadow-light;
}
header .header-wrapper {
  @apply flex items-center justify-between;
}
header .header-logo a {
  @apply block w-full;
}
header .header-logo a img {
  @apply object-contain w-full;
}
@screen xl {
  header .header-logo {
    flex: 0 0 8.5106382979%;
    max-width: 8.5106382979%;
    width: 100%;
  }
}
@media (max-width: 1279.98px) {
  header .header-logo {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    width: 6.1458333333rem;
    height: 2.6041666667rem;
  }
}
header .header-right {
  @apply flex items-center;
}
header .header-menu > ul {
  gap: 4.2708333333rem;
  @apply flex;
}
header .header-menu > ul > li.active > a, header .header-menu > ul > li.active > .title > a, header .header-menu > ul > li:hover > a, header .header-menu > ul > li:hover > .title > a {
  @apply text-primary-500;
}
header .header-menu > ul > li.active > a::after, header .header-menu > ul > li.active > .title > a::after, header .header-menu > ul > li:hover > a::after, header .header-menu > ul > li:hover > .title > a::after {
  @apply w-full opacity-100 visible;
}
header .header-menu > ul > li > a, header .header-menu > ul > li > .title > a {
  padding: 1.3541666667rem 0;
  @apply whitespace-nowrap relative inline-block;
}
header .header-menu > ul > li > a::after, header .header-menu > ul > li > .title > a::after {
  content: "";
  height: 0.1041666667rem;
  @apply w-0 opacity-0 invisible transition-all absolute bottom-0 left-0 bg-linear-2;
}
header .header-menu > ul > li > ul {
  width: 17.96875rem;
  @apply absolute top-full left-0 bg-white py-1 px-6 opacity-0 invisible transition-all;
}
header .header-menu > ul > li > ul > li.active > a, header .header-menu > ul > li > ul > li.active > .title > a, header .header-menu > ul > li > ul > li:hover > a, header .header-menu > ul > li > ul > li:hover > .title > a {
  @apply text-primary-500;
}
header .header-menu > ul > li > ul > li + li {
  @apply border-t border-neutral-100;
}
header .header-menu > ul > li > ul > li > a {
  @apply inline-flex py-5;
}
header .header-menu ul li {
  @apply relative;
}
header .header-menu ul li[class*=has-children]:hover > ul {
  @apply opacity-100 visible;
}
header .header-menu ul li a {
  @apply body-18 transition-all;
}
header .language-wrap {
  margin-left: 3.6458333333rem;
}
header .language-wrap:hover .drop-down {
  @apply opacity-100 visible;
}
header .language-wrap .current-language {
  @apply flex gap-2 items-center;
}
header .language-wrap .drop-down {
  @apply absolute top-full left-0 p-2 bg-white opacity-0 invisible transition-all;
}
header .header-search .searchbox {
  @apply relative;
}
header .header-search .searchbox input {
  height: 2.2916666667rem;
  @apply bg-neutral-50 pl-5 pr-15;
}
header .header-search .searchbox input:placeholder-shown, header .header-search .searchbox input::placeholder {
  @apply text-neutral-950 body-18;
}
header .header-search .searchbox button {
  @apply absolute top-1/2 -translate-y-1/2 right-5 flex-center;
}
header .header-search .searchbox button em {
  @apply text-[#818285] text-2xl;
}
header .header-search-mobile em {
  @apply text-[#818285] text-2xl;
}
header .header-hambuger {
  @apply flex-shrink-0 basis-[calc(36/1920*100rem)] max-w-[calc(36/1920*100rem)] w-full flex flex-col gap-1 mt-auto mb-auto xl:hidden;
}
header .header-hambuger.active span:first-child {
  transform: translateY(7px) rotate(45deg);
}
header .header-hambuger.active span:nth-child(2) {
  transform: scaleX(0);
}
header .header-hambuger.active span:last-child {
  transform: translateY(-7px) rotate(-45deg);
}
header .header-hambuger span {
  height: 0.2604166667rem;
  @apply bg-primary-500 pointer-events-none block w-full transition-all duration-300 rounded-3xl;
}
@media (max-width: 1279.98px) {
  header .header-menu, header .language-wrap, header .header-search {
    @apply hidden;
  }
}

.header-menu-mobile {
  top: var(--header-height);
  @apply fixed left-0 h-screen w-full xl:hidden bg-white;
  @apply transition-all duration-500 -translate-x-full z-100;
}
.header-menu-mobile.active {
  @apply translate-x-0;
}
@screen md {
  .header-menu-mobile {
    width: 22.3958333333rem;
  }
}
.header-menu-mobile .language-wrap ul {
  @apply flex gap-3 py-4 px-5 border-t border-neutral-200;
}
.header-menu-mobile .header-menu > ul > li {
  @apply px-5 border-t border-neutral-200;
}
.header-menu-mobile .header-menu > ul > li.active > a, .header-menu-mobile .header-menu > ul > li.active > .title > a {
  @apply text-primary-500;
}
.header-menu-mobile .header-menu > ul > li > a, .header-menu-mobile .header-menu > ul > li > .title > a {
  @apply inline-flex py-4 sub-header-20 font-bold;
}
.header-menu-mobile .header-menu > ul > li > .title {
  @apply flex justify-between items-center;
}
.header-menu-mobile .header-menu > ul > li.show-child .icon {
  @apply -rotate-180;
}
.header-menu-mobile .header-menu > ul > li .icon {
  @apply flex-center w-8 h-8 transition-all;
}
.header-menu-mobile .header-menu > ul > li .icon::after {
  content: "\f078";
  @apply font-awesome font-normal text-2xl;
}
.header-menu-mobile .header-menu > ul > li > ul {
  @apply pb-5 hidden;
}
.header-menu-mobile .header-menu > ul > li > ul > li + li {
  @apply mt-2;
}

.home-banner .banner-item .box-img {
  height: 100vh;
}
.home-banner .banner-item .box-img img {
  @apply w-full h-full object-cover;
}
.home-banner .banner-item .box-content {
  background-image: linear-gradient(to right, rgba(119, 197, 219, 0.95), rgba(124, 196, 159, 0.95));
  padding: 1.7708333333rem 1.4583333333rem 1.7708333333rem 10.8333333333rem;
}
.home-banner .banner-item .box-content::after {
  content: "";
  clip-path: polygon(1% 0%, 1% 100%, 100% 50%);
  width: 4.1666666667rem;
  background-color: rgba(124, 196, 159, 0.95);
  right: -4.1145833333rem;
  @apply absolute top-0 h-full;
}
@media (max-width: 1023.98px) {
  .home-banner .banner-item .box-content {
    @apply w-full p-5;
  }
  .home-banner .banner-item .box-content::after {
    content: none;
  }
}
@media (max-width: 1279.98px) {
  .home-banner .banner-item .box-img {
    height: 520px;
  }
}

.page-banner .banner-item .box-img img {
  @apply w-full;
}
@media (max-width: 767.98px) {
  .page-banner .box-img img {
    height: 520px;
    @apply object-cover;
  }
}

.contact .infos ul li {
  @apply flex;
}
.contact .infos ul li + li {
  @apply mt-3;
}
.contact .infos ul li .icon {
  flex: 0 0 1.875rem;
  max-width: 1.875rem;
  @apply text-lg text-primary-500 w-full;
}
.contact .map-iframe {
  height: 16.4583333333rem;
}
.contact .map-iframe iframe {
  @apply w-full h-full object-cover;
}
.contact .form-wrap {
  border-radius: 0.4166666667rem;
  @apply bg-neutral-50 px-5 py-8 md:px-12 md:py-16;
}
.contact .form-wrap .desc {
  @apply text-center mb-5;
}
.contact .form-wrap input:not([type=submit]), .contact .form-wrap textarea {
  @apply w-full resize-none rounded-1;
}
.contact .form-wrap input:not([type=submit])::placeholder, .contact .form-wrap textarea::placeholder {
  @apply text-neutral-500 body-16;
}
.contact .form-wrap input:not([type=submit]) {
  height: 2.5rem;
  @apply px-5;
}
.contact .form-wrap input[type=submit] {
  @apply text-0 absolute cursor-pointer inset-0 !important;
}
.contact .form-wrap textarea {
  @apply h-[calc(160/1920*100rem)] py-2.5 px-5;
}
.contact .form-wrap .frm-btn {
  height: 2.7083333333rem;
  background: linear-gradient(225deg, #67C9E2 0%, #70D6A3 100%);
  @apply bg-linear-2 inline-flex items-center px-6 rounded-1 border-primary-300;
}
.contact .form-wrap .frm-btn::after {
  content: "Send";
  @apply body-18 text-white;
}
.contact .form-wrap .form-actions {
  @apply justify-between mt-5 md:items-end flex-col md:flex-row gap-5;
}
.contact .form-wrap .form-actions, .contact .form-wrap .frm-captcha, .contact .form-wrap [id*=_captcha_ctl01] {
  @apply flex;
}
.contact .form-wrap [id*=_captcha_ctl01], .contact .form-wrap .frm-captcha {
  @apply gap-5;
}
.contact .form-wrap [id*=_captcha_ctl01] {
  @apply justify-between;
}
.contact .form-wrap [id*=_captcha_CaptchaLinkButton] {
  display: flex !important;
  @apply text-0 justify-center items-center;
}
.contact .form-wrap [id*=_captcha_CaptchaLinkButton]::after {
  content: "\f01e";
  @apply font-awesome font-black text-32 text-primary-700;
}
.contact .form-wrap .frm-captcha-input label {
  @apply hidden;
}
.contact .form-wrap .frm-captcha {
  @apply sm:items-end flex-col sm:flex-row;
}
.contact .form-wrap [id*=_ctl02_captcha_ctl00] {
  color: #ef4444;
  @apply text-sm font-primary font-normal;
}
.contact .form-wrap .fa-exclamation-triangle {
  @apply static translate-x-0 translate-y-0;
}

.vi-vn .contact .form-wrap .frm-btn::after {
  content: "Gửi";
}

.about-1 .desc p + p {
  @apply mt-3;
}

@screen xl {
  .about-2::before {
    content: "";
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
    height: 23.2291666667rem;
    top: 5.3125rem;
    @apply absolute w-full left-0;
  }
  .about-2.section-large {
    padding-top: 5.3125rem;
    padding-bottom: 24.0625rem;
  }
}
@media (max-width: 1279.98px) {
  .about-2 {
    background-image: none !important;
  }
}

.about-3 .desc p + p {
  @apply mt-3;
}

.about-8 .swiper-slide {
  @apply border border-neutral-200;
}
.about-8 .thumbnail {
  @apply flex-center py-5;
}
.about-8 .thumbnail img {
  width: 80%;
}

.home-1 {
  @apply overflow-hidden;
}
.home-1::before {
  content: "";
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
  height: 23.2291666667rem;
  @apply w-full absolute top-0 left-0;
}
@media (max-width: 1279.98px) {
  .home-1 {
    background-image: none !important;
  }
  .home-1::before {
    content: none;
  }
}
.home-1 .home-1-item {
  outline: 2px solid rgba(255, 255, 255, 0.7);
}
.home-1 .home-1-item::before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  height: 3.0208333333rem;
  @apply w-full absolute bottom-0 left-0 z-1;
}

.home-2 .home-2-item::before {
  content: "";
  @apply bg-black opacity-40 inset-0 absolute z-1 c-transall;
}
.home-2 .innert-content {
  @apply z-2 relative text-white;
}
@screen xl {
  .home-2 .home-2-item {
    height: 39.5833333333rem;
    @apply overflow-hidden;
    background-image: none !important;
  }
  .home-2 .home-2-item::before {
    @apply opacity-0;
  }
  .home-2 .home-2-item + .home-2-item::after {
    content: "";
    width: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    @apply absolute left-0 bottom-0 h-full z-2;
  }
  .home-2 .home-2-item .innert-content {
    transform: translateY(calc(100% - 6.875rem));
    @apply absolute z-2 left-0 bottom-0 p-10 c-transall;
  }
  .home-2 .home-2-item .innert-content::after {
    content: "";
    background: linear-gradient(225deg, #67C9E2 0%, #70D6A3 100%);
    @apply w-full absolute bottom-0 left-0 h-1 bg-linear-2 c-transall opacity-0;
  }
  .home-2 .home-2-item:hover::before {
    @apply opacity-40;
  }
  .home-2 .home-2-item:hover .innert-content {
    transform: translateY(0);
  }
  .home-2 .home-2-item:hover .desc, .home-2 .home-2-item:hover .button-wrapper, .home-2 .home-2-item:hover .innert-content::after {
    @apply opacity-100;
  }
  .home-2 .home-2-item .desc {
    @apply opacity-0 c-transall;
  }
  .home-2 .home-2-item .button-wrapper {
    @apply opacity-0 c-transall;
  }
}
@media (max-width: 1279.98px) {
  .home-2 .home-2-item {
    @apply px-5 py-8;
  }
  .home-2 .home-2-content {
    background-image: none !important;
  }
}

@screen xl {
  .home-3 .swiper-slide {
    height: calc(50% - 16px) !important;
  }
}

.news-detail .column-right .news-other-item {
  @apply mt-5 flex justify-between;
}
.news-detail .column-right .news-other-item .box-img, .news-detail .column-right .news-other-item .box-content {
  @apply w-full;
}
.news-detail .column-right .news-other-item .box-img {
  flex: 0 0 30%;
  max-width: 0 0 30%;
}
.news-detail .column-right .news-other-item .box-content {
  flex: 1;
  max-width: 100%;
  @apply flex flex-col xl:justify-center px-5 pt-4 xl:pt-0 bg-neutral-50;
}

.fullcontent p > img {
  margin-bottom: 0;
}

.news-item:hover {
  @apply shadow-dropshadow-medium bg-white;
}
.news-item:hover .title {
  @apply text-primary-500;
}
.news-item:hover .box-img img {
  @apply scale-105;
}

.project-detail .column-left .desc p + p {
  @apply mt-3;
}
.project-detail .column-right {
  background: linear-gradient(180deg, #5EC2DC 0%, #5FD198 100%);
}
.project-detail .column-right ul {
  @apply h-full flex flex-col justify-center;
}
.project-detail .column-right ul li {
  @apply flex gap-5 py-5 items-center;
}
.project-detail .column-right ul li + li {
  @apply border-t border-white/20;
}
.project-detail .column-right ul li .icon {
  flex: 0 0 28px;
  max-width: 28px;
  @apply w-full;
}
.project-detail .column-right ul li .icon img {
  filter: brightness(0) invert(1);
  @apply w-full object-contain;
}
.project-detail .column-right ul li span {
  @apply body-18;
}

.socials-share {
  top: 8.3333333333rem;
  @apply xl:sticky mt-5 xl:mt-0;
}
.socials-share ul {
  @apply flex gap-2;
}
@screen xl {
  .socials-share ul {
    @apply flex-col;
  }
}
.socials-share ul a {
  @apply flex-center h-12 w-12 rounded-full border border-primary-600 text-primary-600 cursor-pointer;
}
.socials-share ul a i {
  @apply text-xl;
}

.project-equipment table tr + tr {
  @apply border-t border-neutral-200;
}
.project-equipment table tr td {
  @apply py-3;
}
.project-equipment table tr td:first-child {
  width: 8.6979166667rem;
  @apply font-bold;
}
.project-equipment .swiper-inner .wrap-button-slide {
  @apply absolute bottom-0 right-0;
}
.project-equipment .swiper-inner .btn-prev, .project-equipment .swiper-inner .btn-next {
  height: 3.5416666667rem;
  width: 2.6041666667rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  @apply static translate-y-0 rounded-none;
}
.project-equipment .swiper-inner .btn-prev::before, .project-equipment .swiper-inner .btn-next::before {
  @apply font-normal;
}
.project-equipment .swiper-inner .btn-prev:hover, .project-equipment .swiper-inner .btn-next:hover {
  @apply bg-transparent;
}

.project-item::after {
  content: "";
  height: 2px;
  @apply w-full absolute left-0 bottom-0 bg-neutral-200 c-transall;
}
@screen xl {
  .project-item:hover {
    @apply shadow-dropshadow-medium;
  }
  .project-item:hover::after {
    @apply bg-linear-2;
  }
  .project-item:hover .title {
    @apply text-primary-500;
  }
  .project-item:hover .box-img img {
    @apply scale-105;
  }
}

.project-other .project-item {
  @apply bg-neutral-50;
}

.project-item-large .desc li, .project-item .desc li {
  @apply flex gap-3 text-neutral-800;
}
.project-item-large .desc li + li, .project-item .desc li + li {
  @apply mt-1;
}
.project-item-large .desc li .icon, .project-item .desc li .icon {
  flex: 0 0 20px;
  max-width: 20px;
  @apply w-full;
}
.project-item-large .desc li .icon img, .project-item .desc li .icon img {
  @apply object-contain w-full;
}

.project-item-large .column-right {
  @apply flex flex-col xl:justify-center xl:border-b xl:border-neutral-200;
}

.popup-product .column-left .wrap-button-slide {
  @apply absolute bottom-0 right-0;
}
.popup-product .column-left .btn-prev, .popup-product .column-left .btn-next {
  height: 3.5416666667rem;
  width: 2.6041666667rem;
  @apply static translate-y-0 bg-transparent rounded-none;
}
.popup-product .column-left .btn-prev::before, .popup-product .column-left .btn-next::before {
  @apply text-primary-500 font-normal;
}
.popup-product .column-left .btn-prev:hover, .popup-product .column-left .btn-next:hover {
  @apply bg-transparent;
}
.popup-product .column-left .btn-prev:hover::before, .popup-product .column-left .btn-next:hover::before {
  @apply text-primary-500;
}
.popup-product .column-left, .popup-product .column-right {
  flex: 0 0 100%;
  max-width: 100%;
  @apply w-full;
}
.popup-product .column-left table tr + tr, .popup-product .column-right table tr + tr {
  @apply border-t border-neutral-200;
}
.popup-product .column-left table tr td, .popup-product .column-right table tr td {
  @apply py-3;
}
.popup-product .column-left table tr td:first-child, .popup-product .column-right table tr td:first-child {
  width: 8.6979166667rem;
  @apply font-bold;
}
@screen xl {
  .popup-product .wrapper {
    height: 27.2916666667rem;
    @apply overflow-y-auto;
  }
  .popup-product .column-left {
    flex: 0 0 60.5697151424%;
    max-width: 60.5697151424%;
  }
  .popup-product .column-right {
    flex: 0 0 36.9565217391%;
    max-width: 36.9565217391%;
  }
}
@media (max-width: 1279.98px) {
  .popup-product .column-right {
    @apply p-5;
  }
}

.product-item:hover {
  @apply shadow-dropshadow-medium;
}
.product-item:hover .title {
  @apply text-primary-500;
}
.product-item:hover .box-img img {
  @apply scale-105;
}