footer
	.footer-infos
		@apply mt-6
		h4
			@apply sub-header-20 font-bold
		p
			@apply mt-2
			a
				@apply border-b
				em
					@apply mr-2
	.footer-contact
		@apply mt-6
		ul
			li+li
				@apply mt-1
	.footer-nav
		ul
			li
				&+li
					@apply mt-4
				a
					@apply sub-header-20 font-bold
		@screen xl
			border-left: 1px solid rgba(255, 255, 255, 0.50)
			@apply pl-10
	.footer-social
		ul
			@apply flex items-center gap-2
			li
				a
					@apply flex-center w-12 h-12 rounded-full bg-white
					em
						@apply text-primary-600 text-2xl
