.global-title-primary
	@apply header-40 font-bold text-primary-500
.global-title-secondary
	@apply section-header-32 text-primary-500 font-bold
.global-title-thircondary
	@apply sub-header-20 font-bold text-primary-500

.global-tab
	ul
		@apply flex gap-5 md:gap-8 overflow-x-auto
		li
			&.active
				a
					@apply text-primary-500
					&::after
						@apply w-full bg-primary-500
			a
				@apply whitespace-nowrap relative text-neutral-700 font-bold c-transall
				&::after
					content: ''
					height: rr(1px)
					@apply c-transall w-full bottom-0 left-0 bg-transparent absolute
	@screen xl
		ul
			li
				&:hover
					a
						@apply text-primary-500
						&::after
							@apply w-full bg-primary-500
				a
					&::after
						@apply w-0