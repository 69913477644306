.news-detail
	.column-right
		.news-other-item
			@apply mt-5 flex justify-between
			.box-img, .box-content
				@apply w-full
			.box-img
				flex: 0 0 calc(144/480*100%)
				max-width: 0 0 calc(144/480*100%)
			.box-content
				flex: 1
				max-width: 100%
				@apply flex flex-col xl:justify-center px-5 pt-4 xl:pt-0 bg-neutral-50

.fullcontent
	p
		&>img
			margin-bottom: 0