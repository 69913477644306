.project-detail
	.column-left
		.desc
			p
				&+p
					@apply mt-3
	.column-right
		background: linear-gradient(180deg, #5EC2DC 0%, #5FD198 100%)
		ul
			@apply h-full flex flex-col justify-center
			li
				@apply flex gap-5 py-5 items-center
				&+li
					@apply border-t border-white/20
				.icon
					flex: 0 0 28px
					max-width: 28px
					@apply w-full
					img
						filter: brightness(0) invert(1)
						@apply w-full object-contain
				span
					@apply body-18
.socials-share
	top: rr(160px)
	@apply xl:sticky mt-5 xl:mt-0
	ul
		@apply flex gap-2
		@screen xl
			@apply flex-col
		a
			@apply flex-center h-12 w-12 rounded-full border border-primary-600 text-primary-600 cursor-pointer
			i
				@apply text-xl

.project-equipment
	table
		tr
			&+tr
				@apply border-t border-neutral-200
			td
				@apply py-3
				&:first-child
					width: rr(167px)
					@apply font-bold
	.swiper-inner
		.wrap-button-slide
			@apply absolute bottom-0 right-0
		.btn-prev, .btn-next
			height: rr(68px)
			width: rr(50px)
			background: rgba(255, 255, 255, 0.20)
			backdrop-filter: blur(2px)
			@apply static translate-y-0 rounded-none
			&::before
				@apply font-normal
			&:hover
				@apply bg-transparent
