.about-2
	@screen xl
		&::before
			content: ''
			background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)
			height: rr(446px)
			top: rr(102px)
			@apply absolute w-full left-0
		&.section-large
			padding-top: rr(102px)
			padding-bottom: rr(462px)
	@media(max-width: 1279.98px)
		background-image: none !important
