.btn
	@apply inline-flex items-center cursor-pointer c-transall
	@apply px-6 text-white
	&.btn-primary
		height: rr(52px)
		background: linear-gradient(225deg, #67C9E2 0%, #70D6A3 100%)
		@apply border border-primary-300 rounded-1 bg-linear-2
		span
			@apply body-18
		&:hover
			background: #007DD3
			@apply border-primary-500
