.pagination
	@apply flex-center gap-5 mt-5 xl:mt-8
	li
		&.active
			a, span
				@apply bg-primary-600 text-white
	a, span
		@apply bg-neutral-50 rounded-full text-neutral-400 sub-header-20
		@apply inline-flex justify-center items-center w-12 h-12
		@apply c-transall
		&:hover
			@apply bg-primary-600 text-white
		&.FirstPage, &.BackPage, &.NextPage, &.LastPage
			@apply hidden
