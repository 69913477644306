.contact
	.infos
		ul
			li
				@apply flex
				&+li
					@apply mt-3
				.icon
					flex: 0 0 rr(36px)
					max-width: rr(36px)
					@apply text-lg text-primary-500 w-full
	.map-iframe
		height: rr(316px)
		iframe
			@apply w-full h-full object-cover
	.form-wrap
		border-radius: rr(8px)
		@apply bg-neutral-50 px-5 py-8 md:px-12 md:py-16
		.desc
			@apply text-center mb-5
		input:not([type="submit"]), textarea
			@apply w-full resize-none rounded-1
			&::placeholder
				@apply text-neutral-500 body-16
		input
			&:not([type="submit"])
				height: rr(48px)
				@apply px-5
			&[type="submit"]
				@apply text-0 absolute cursor-pointer inset-0 #{!important}
		textarea
			@apply h-[calc(160/1920*100rem)] py-2.5 px-5
		.frm-btn
			height: rr(52px)
			background: linear-gradient(225deg, #67C9E2 0%, #70D6A3 100%)
			@apply bg-linear-2 inline-flex items-center px-6 rounded-1 border-primary-300
			&::after
				content: 'Send'
				@apply body-18 text-white 
		.form-actions
			@apply justify-between mt-5 md:items-end flex-col md:flex-row gap-5
		.form-actions, .frm-captcha, [id*='_captcha_ctl01']
			@apply flex
		[id*='_captcha_ctl01'], .frm-captcha
			@apply gap-5
		[id*='_captcha_ctl01']
			@apply justify-between
		[id*='_captcha_CaptchaLinkButton']
			display: flex !important
			@apply text-0 justify-center items-center
			&::after
				content: '\f01e'
				@apply font-awesome font-black text-32 text-primary-700
		.frm-captcha-input
			label
				@apply hidden
		.frm-captcha
			@apply sm:items-end flex-col sm:flex-row
		[id*='_ctl02_captcha_ctl00']
			color: #ef4444
			@apply text-sm font-primary font-normal
		.fa-exclamation-triangle
			@apply static translate-x-0 translate-y-0

.vi-vn
	.contact
		.form-wrap
			.frm-btn
					&::after
						content: 'Gửi'