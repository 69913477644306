.loginpage
	.panel-body
		.flex.justify-between.mt-3
			display: none
	header
		display: none
	footer
		display: none
	main
		background-color: #F0F0F1
		display: table-cell
		vertical-align: middle
		height: 100vh
		width: 100vw
		padding-top: 0 !important
		padding: 0 15px
	.header-tools-mobile
		display: none !important
	.login-box
		max-width: 690px
		margin: 0 auto
		width: 100%
		.login-logo
			display: flex
			align-items: center
			justify-content: center
			font-size: 36px
			flex-direction: column
			font-size: 0
			margin-bottom: 30px
			&::before
				content: ''
				display: inline-block
				background-image: url('/Data/Sites/1/skins/default/img/logo-canh-cam.png')
				width: 267px
				height: 49px
				background-repeat: no-repeat
				background-size: 267px auto
				margin-bottom: 15px
		.loginstandard
			background-color: #fff
			box-shadow: 0, 4px, 10px rgba(0,0,0,0.05)
			border-radius: 10px
			border: 0
			padding: 40px 60px 30px
			font-weight: 400
			overflow: hidden
			font-family: Roboto, sans-serif !important
			@media (max-width: 767.98px)
				padding: 15px 20px
			.card-body
				.row.items-center
					@apply flex-wrap mx-0
					.col-12
						flex: 0 0 100%
						max-width: 100%
				.login-box-msg
					display: none
				.panel-body
					.input-group
						position: relative
						margin-bottom: r(20px)
						.input-group-append
							position: absolute
							top: 50%
							transform: translateY(-50%)
							right: 15px
							color: #d1d1d1
						input
							background: #f5f5f5
							border-radius: 25px
							height: 50px
							border: 0
							outline: none
							box-shadow: 0 0 0 1px #f5f5f5
							transition: 0.3s all ease-in-out
							padding: 0 35px
							font-size: 14px
							width: 100%
							border: 1px solid transparent
							&:hover,&:focus
								border: 1px solid #f73936
					.icheck-primary
						display: flex
						align-items: center
						font-size: 14px
						gap: 10px
						margin-bottom: 20px
					.btn-primary
						background-color: #f73936
						border-radius: 25px
						height: 50px
						display: flex
						align-items: center
						justify-content: center
						color: #ffffff
						font-weight: bold
						width: 100%
						font-size: 20px
						border: 0
						outline: none
						transition: 0.3s all ease-in-out
					.flex.justify-between.mt-3
						margin-top: 30px
						font-size: 14px
					.cart-login
						display: none
				span.alert
					color: #f73936
					display: block
					margin-bottom: 10px
					text-align: center