@import 'src/components/_core/_mixins'
@import 'plugins/Wordpress/form'

.edit-link i:before
	content: '✏️'

.fancybox__content
	> .carousel__button.is-close
		@media(max-width: 575.89px)
			right: 0 !important
			top: -34px !important
