.project-item
	&::after
		content: ''
		height: 2px
		@apply w-full absolute left-0 bottom-0 bg-neutral-200 c-transall
	@screen xl
		&:hover
			@apply shadow-dropshadow-medium
			&::after
				@apply bg-linear-2
			.title
				@apply text-primary-500
			.box-img
				img
					@apply scale-105
.project-other
	.project-item
		@apply bg-neutral-50

.project-item-large, .project-item
	.desc
		li
			@apply flex gap-3 text-neutral-800
			&+li
				@apply mt-1
			.icon
				flex: 0 0 20px
				max-width: 20px
				@apply w-full
				img
					@apply object-contain w-full

.project-item-large
	.column-right
		@apply flex flex-col xl:justify-center xl:border-b xl:border-neutral-200