.global-breadcrumb
	@apply bg-white border-b border-neutral-200
	ol
		@apply flex items-center py-3 overflow-x-auto text-neutral-500
	li
		&:not(:first-child)
			&::before
				content: '\f105'
				@apply font-awesome mx-2 font-thin text-base
		a
			@apply body-14 whitespace-nowrap