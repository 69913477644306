.section-large
	@apply py-10
	@screen md
		@apply py-25
.section-medium
	@apply py-10
	@screen md
		@apply py-20
.section-small
	@apply py-7.5
	@screen md
		@apply py-15