.home-banner
	.banner-item
		.box-img
			height: 100vh
			img
				@apply w-full h-full object-cover
		.box-content
			background-image: linear-gradient(to right, rgb(119 197 219 / 95%), rgb(124 196 159 / 95%))
			padding: rr(34px) rr(28px) rr(34px) rr(208px)
			&::after
				content: ''
				clip-path: polygon(1% 0%, 1% 100%, 100% 50%)
				width: rr(80px)
				background-color: rgb(124 196 159 / 95%)
				right: rr(-79px)
				@apply absolute top-0 h-full
			@media(max-width: 1023.98px)
				@apply w-full p-5
				&::after
					content: none
		@media(max-width: 1279.98px)
			.box-img
				height: 520px